<template>
  <div class="home">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/index/Footer.vue";
import Header from "../components/index/Header.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
</style>