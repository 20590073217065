<template>
  <div id="app">
    <!-- <router-view @toim="toim" /> -->
    <router-view />
    <div class="contact">
      <!-- <div class="org" @click="toIM">
        <img src="./assets/img/Iconly_Light_Chat.png" alt="" />
        <p>洽谈合作</p>
      </div>
      <div @click="toIM">
        <img src="./assets/img/Iconly_Light_Profile.png" alt="" />
        <p>在线客服</p>
      </div> -->
      <div class="tel">
        <img src="./assets/img/Iconly_Light_Call1.png" alt="" />
        <p>联系电话</p>
        <div class="wtes">Tel: {{ tel }}</div>
      </div>
      <div class="wte" @click="totop">
        <img src="./assets/img/Iconly_Light_Arrow - Up.png" alt="" />
        <p>返回顶部</p>
      </div>
    </div>
    <!-- <tencent v-show="contentShow" @close="closeDialog"></tencent> -->
  </div>
</template>
<script>
// import { mapState } from "vuex";
// import MTA from "./utils/mta";
// import tencent from "./components/tencentIM/index.vue";
export default {
  components: {
    // tencent,
  },
  data() {
    return {
      contentShow: false,
      tel: null,
      // im: function () {
      //   this.contentShow = true;
      // },
    };
  },
  created() {
    if (sessionStorage.getItem("store")) {
      console.log(222);
      // 刷新覆盖vuex
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store")))
      );
    }
    window.addEventListener("beforeunload", () => {
      // 将vuex存到缓存中
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    // toim() {
    //   this.toIM();
    // },
    // toIM() {
    //   if (!localStorage.getItem("member_id")) {
    //     this.$router.push("/login");
    //   } else {
    //     this.contentShow = true;
    //   }
    // },
    closeDialog() {
      this.contentShow = false;
    },
    // 返回顶部
    totop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 获取电话
    getData() {
      const that = this;
      that.$http
        .postApi("/index", {})
        .then((res) => {
          if (res.data.code === 200) {
            that.tel = res.data.data.officer_tel;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 监听页面是否活动
    aaa() {
      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      function handleVisibilityChange() {
        if (document[hidden]) {
          // todo something
          console.log("页面不活动");
        } else {
          // todo something
          console.log("页面活动！");
        }
      }
      if (
        typeof document.addEventListener === "undefined" ||
        typeof document[hidden] === "undefined"
      ) {
        console.log("不支持");
      } else {
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
      }
    },
  },
  mounted() {
    this.getData();
    // 定位城市
    if (!this.$cookies.get("location")) {
      let locl = {
        code: "370200",
        city: "青岛市",
      };
      this.$cookies.set("location", locl, "1d");
    } else {
      this.$store.commit("setLocal", this.$cookies.get("location"));
    }
    this.aaa();
    // pv统计
    // var location = window.location,
    //   oldURL = location.href,
    //   oldHash = location.hash;
    // setInterval(function () {
    //   var newURL = location.href,
    //     newHash = location.hash;
    //   console.log(newURL, newHash);
    //   // 如果hash发生了变化,且绑定了处理函数...
    //   if (newHash != oldHash) {
    //     oldURL = newURL;
    //     oldHash = newHash;
    //     // uploadUserData(4, null);
    //   }
    // }, 5000);
    // if (localStorage.getItem('member_id')) {
    //   this.$store.dispatch("login");
    // }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem("userInfo")) || {};
    },
    // ...mapState({
    //   isLogin: (state) => state.user.isLogin,
    // }),
  },
  watch: {
    // isLogin(next) {
    //   if (next) {
    //     MTA.clickStat("link_two", { show: "true" });
    //   }
    // },
  },
};
</script>
