import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import http from "../components/utils/api";
import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: "/homePage",
    children: [{
        path: "/homePage", // 首页
        name: "homePage",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/index/homePage.vue"
          ),
      },
      {
        path: "/location", // 定位
        name: "location",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/index/location.vue"
          ),
      },
      {
        path: "/searchBar", // 搜索
        name: "searchBar",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/index/searchBar.vue"
          ),
      },
      {
        path: "/goodslist", // 商品分类
        name: "goodslist",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/order/goodslist.vue"
          ),
      },
      {
        path: "/detail", // 商品详情
        name: "detail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/order/detail/index.vue"
          ),
      },
      {
        path: "/order", // 确认订单
        name: "order",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/sure.vue"
          ),
      },
      {
        path: "/shoppingCartItem", // 购物车
        name: "shoppingCartItem",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/cart.vue"
          ),
      },
      {
        path: "/payment", // 付款
        name: "payment",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/shopping/payment.vue"
          ),
      },
      {
        path: "/helpCenter", // 帮助中心
        name: "helpCenter",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/activities/list.vue"
          ),
      },
      {
        path: "/activity", // 行业资讯
        name: "activity",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/information/index.vue"
          ),
      },
      {
        path: "/actProfile", // 活动详情
        name: "actProfile",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/information/detail.vue"
          ),
      },
      {
        path: "/helpProfile", // 帮助详情
        name: "helpProfile",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/activities/detail.vue"
          ),
      },
      {
        path: "/pc_agree", // 隐私政策
        name: "pc_agree",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/index/agreement.vue"
          ),
      },
      {
        path: "/pc_policy", // 用户协议
        name: "pc_policy",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/index/agreement.vue"
          ),
      },
      {
        path: "/uCenter", // 个人中心
        name: "uCenter",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/index.vue"
          ),
      },
      // {
      //   path: "/myProfile", // 个人中心-账号设置
      //   name: "myProfile",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "group-foo" */
      //       "../components/Personalizar/index.vue"
      //     ),
      // },
      // {
      //   path: "/myProfile", // 个人中心-方案管理
      //   name: "myProfile",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "group-foo" */
      //       "../components/Personalizar/index.vue"
      //     ),
      // },
      // {
      //   path: "/myFavorites", // 个人中心-我的收藏
      //   name: "myFavorites",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "group-foo" */
      //       "../components/Personalizar/index.vue"
      //     ),
      // },
      // {
      //   path: "/myOrders", // 个人中心-我的订单
      //   name: "myOrders",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "group-foo" */
      //       "../components/Personalizar/index.vue"
      //     ),
      // },
      // {
      //   path: "/cipher", // 个人中心-修改密码
      //   name: "cipher",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "group-foo" */
      //       "../components/Personalizar/index.vue"
      //     ),
      // },
      {
        path: "/schedule", // 展具订单详情
        name: "schedule",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/orderDetail/schedule.vue"
          ),
      },
      {
        path: "/PlanOrder", // 方案订单详情
        name: "PlanOrder",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/orderDetail/PlanOrder.vue"
          ),
      },
      {
        path: "/Requirements", // 方案需求订单详情
        name: "Requirements",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/orderDetail/PlanOrder.vue"
          ),
      },
      {
        path: "/customized", // 定制设计
        name: "customized",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Custom/index.vue"
          ),
      },
      {
        path: "/design", // 定制设计-定制详情
        name: "design",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Custom/detail.vue"
          ),
      },
      {
        path: "/demand", // 定制设计-提交需求
        name: "demand",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Custom/demand.vue"
          ),
      },
      {
        path: "/specialDesign", // 定制设计-特装展具设计
        name: "specialDesign",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Custom/design.vue"
          ),
      },
      {
        path: "/construct", // 搭建公司
        name: "construct",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/setUpCompany/index.vue"
          ),
      },
      {
        path: "/construct/company", // 搭建公司列表
        name: "company",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/setUpCompany/company.vue"
          ),
      },
      {
        path: "/construct/detail", // 搭建公司详情
        name: "companyDetail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/setUpCompany/detail.vue"
          ),
      },
      {
        path: "/franchisee", // 加盟合作
        name: "franchisee",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/franchisee/index.vue"
          ),
      },
      {
        path: "/enterprises", // 企业入驻
        name: "enterprises",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/enterprises/index.vue"
          ),
      },
      {
        path: "/Demandhall", // 需求大厅
        name: "Demandhall",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/DemandHall/index.vue"
          ),
      },
      {
        path: "/Demandhall/detail", // 需求大厅-需求详情
        name: "DemandDetail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/DemandHall/Detail.vue"
          ),
      },
      {
        path: "/Demandhall/auction", // 需求大厅-参与竞单
        name: "DemandAuction",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/DemandHall/auction.vue"
          ),
      },
      {
        path: "/billOrder", // 发单订单详情
        name: "billOrder",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/BillList/Detail.vue"
          ),
      },
      {
        path: "/mitteeDetail", // 组委会发单明细
        name: "mitteeDetail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/BillList/mitteeDetail.vue"
          ),
      },
      {
        path: "/receDetail", // 企业接单详情
        name: "receDetail",
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */
            "../components/Personalizar/orderDetail/receDetail.vue"
          ),
      },
    ]
  },
  {
    path: "/login", // 登录
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/index/login.vue"
      ),
  },
  {
    path: "/register", // 注册
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/index/login.vue"
      ),
  },
  {
    path: "/resetPass", // 忘记密码
    name: "resetPass",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */
        "../components/index/login.vue"
      ),
  },
  {
    path: "/agreement", // app-隐私协议
    name: "agreement",
    component: () => import("../views/app/agreement.vue")
  },
  {
    path: "/policy", // app-用户协议
    name: "policy",
    component: () => import("../views/app/policy.vue")
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 用户信息
// async function getinfo() {
//   const params = {
//     member_id: localStorage.getItem("member_id"),
//   };
//   try {
//     const {
//       data: res
//     } = await http.postApi('/member_info', params)
//     if (res.code === 200) {
//       store.commit('setUserInfo', res.data)
//     }
//   } catch (error) {
//     store.commit('setUserInfo', {})
//     return false
//   }
// }

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  const tokenStr = window.localStorage.getItem("token");
  if (tokenStr) {
    next();
  } else {
    console.log(to.path)
    if (to.path === "/uCenter" || to.path === "/order" || to.path === "/PlanOrder" || to.path === "/demand" || to.path === "/specialDesign" || to.path === "/enterprises" || to.path === "/schedule") {
      next("/login");
    } else {
      next();
    }
  }
});

// router.afterEach((to, from, failure) => {
//   const tokenStr = window.localStorage.getItem("token");
//   if (tokenStr && JSON.stringify(store.state.userInfo) === '{}') {
//     console.log(222)
//     getinfo()
//   }
// })
export default router