/*
 * @Author: your name
 * @Date: 2021-06-20 22:16:14
 * @LastEditTime: 2021-11-08 11:32:21
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjzl-index\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
// import conversation from './modules/conversation'
// import group from './modules/group'
// import groupLive from './modules/groupLive'
// import user from './modules/user'
// import friend from './modules/friend'
import goods from './modules/goods'
import {Message} from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    state: false,
    current: Date.now(), // 当前时间
    intervalID: 0,
    message: undefined,
    location: {},
    userInfo: {},
    userAvr: '',
    nickname: ''
  },
  mutations: {
    setAvr(state, payload){
      state.userInfo.pic = payload
    },
    setNick(state, payload){
      state.nickname = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    setLocal(state, payload) {
      state.location = payload
    },
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now()
      }, 500)
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID)
      state.intervalID = 0
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close()
      }
      state.message = Message({
        message: options.message,
        type: options.type || 'success',
        duration: options.duration || 2000,
        offset: 40
      })
    }
  },
  getters: {
    hidden(state) {
      // eslint-disable-next-line no-unused-vars
      const temp = state.current 
      if (typeof document.hasFocus !== 'function') {
        return document.hidden
      }
      return !document.hasFocus()
    }
  },
  actions: {
  },
  modules: {
    // conversation,
    // group,
    // friend,
    // user,
    // groupLive,
    goods
  }
})
