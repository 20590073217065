import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import postApi from "./components/utils/api";
import './assets/css/font.scss';
import {
  MessageBox,
  Row,
  Col,
  Button,
  Input,
  Loading,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  CheckboxGroup,
  Empty
} from 'element-ui'
import lazyload from "vue-lazyload"
// import Avatar from './components/im/avatar.vue'
// import tim from 'tim'
// import TIM from 'tim-js-sdk'
import VueClipboard from 'vue-clipboard2'
import './assets/icon/iconfont.css'
import './assets/icon/tim.css'
import './assets/css/animate.css'
import './assets/css/index.css'
import TEditor from '@/components/TEditor.vue'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

// window.tim = tim
// window.TIM = TIM
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
// Vue.prototype.tim = tim
// Vue.prototype.TIM = TIM

Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.use(lazyload);
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Empty)
Vue.component('TEditor', TEditor)
Vue.use(VueCookies)
// Vue.component('avatar', Avatar)

Vue.prototype.$http = postApi;
Vue.prototype.$confirm = MessageBox.confirm

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')