<template>
  <div class="headers">
    <div class="nav">
      <div class="content navbar">
        <div class="city">
          <img src="../../assets/newImg/dingwei.png" alt="" />
          <span>{{ city }}</span>
          <span class="cut" @click="$router.push('/location')">【切换城市】</span>
        </div>
        <ul class="funBtn">
          <li @click="$router.push('/enterprises')" v-if="company_id == 0">
            <img src="../../assets/newImg/qiye.png" alt="" />
            企业入驻
          </li>
          <li @click="$router.push('/login')" v-if="!token">
            <el-avatar
              :size="31"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              style="vertical-align: middle"
            ></el-avatar>
            登录/注册
          </li>
          <li v-if="token" @click="$router.push('/uCenter')">
            <el-avatar
              :size="31"
              :src="userAvr"
              style="vertical-align: middle"
            ></el-avatar>
            {{ nickname }}
          </li>
          <li v-if="token" @click="logout">
            <img src="../../assets/newImg/tuichu.png" alt="" />
            退出
          </li>
        </ul>
      </div>
    </div>
    <div class="border-e6e" v-if="$route.path !== '/location'">
      <div class="content bannerShow">
        <div class="logos">
          <div @click="$router.push('/HomePage')">
            <img src="../../assets/newImg/logo.png" alt="" />
            <span>展具365</span>
          </div>
          <span>一站式服务 展览无忧</span>
        </div>
        <div class="searchBar">
          <el-input
            v-model="searchText"
            class="searchInput"
            placeholder="想要什么？搜搜看"
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option label="设计方案" value="1"></el-option>
              <el-option label="搭建公司" value="2"></el-option>
              <el-option label="展具" value="3"></el-option>
              <el-option label="资讯" value="4"></el-option>
              <el-option label="需求项目" value="6"></el-option>
            </el-select>
          </el-input>
          <el-button type="primary" class="searchBtn" @click="search">搜索</el-button>
        </div>
      </div>
    </div>
    <div
      class="navigation"
      v-if="$route.path !== '/location' && $route.path !== '/searchBar'"
    >
      <div class="content navigat">
        <div
          :class="{
            act:
              $route.path === item.url ||
              (item.child && item.child.includes($route.path)),
          }"
          v-for="(item, index) in nav"
          :key="index"
        >
          <router-link target="_blank" :to="item.url">{{ item.title }}</router-link>
        </div>
      </div>
    </div>
    <div v-if="$route.path === '/location'">
      <img src="../../assets/newImg/city.png" class="bannerImg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchText: "",
      select: "1",
      nav: [
        {
          title: "首页",
          url: "/homePage",
        },
        {
          title: "需求大厅",
          url: "/Demandhall",
          child: ["/Demandhall/detail", "/Demandhall/auction"],
        },
        {
          title: "设计定制",
          url: "/customized",
          child: ["/design", "/demand", "/specialDesign"],
        },
        {
          title: "搭建公司",
          url: "/construct",
          child: ["/construct/company", "/construct/detail"],
        },
        {
          title: "展具商城",
          url: "/goodslist",
          child: ["/detail"],
        },
        // {
        //   title: "合作加盟",
        //   url: "/franchisee",
        // },
      ],
    };
  },
  methods: {
    // 获取所在城市信息
    getLocalion() {
      const that = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            that.judgeLocal(result.adcode);
          }
        });
      });
    },
    // 判断城市是否存在
    judgeLocal(local) {
      const that = this;
      that.$http.postApi("/switch_city", { area_id: local }).then((res) => {
        if (res.data.code === 200) {
          let locl = {
            code: res.data.data.code,
            city: res.data.data.city,
          };
          this.$store.commit("setLocal", locl);
          this.$cookies.set("location", locl);
        }
      });
    },
    // 退出登录
    logout() {
      const that = this;
      that.$http.postApi("/logout", { member_id: that.member_id }).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("setUserInfo", {});
          localStorage.clear();
          window.location.reload();
        }
      });
    },
    // 搜索
    search() {
      if (!this.searchText) {
        this.$message.error("请输入内容再点搜索");
        return;
      }
      let data = encodeURIComponent(
        JSON.stringify({
          opt: this.select,
          keyword: this.searchText,
        })
      );
      if (this.$route.path !== '/searchBar') {
        this.$router.push({ path: "/searchBar", query: { s: data } });
      } else {
        this.$router.replace({ path: "/searchBar", query: { s: data } });
      }
    },
    // 用户信息
    async getinfo() {
      const params = {
        member_id: localStorage.getItem("member_id"),
      };
      try {
        const { data: res } = await this.$http.postApi("/member_info", params);
        if (res.code === 200) {
          console.log(111)
          this.$store.commit("setUserInfo", res.data);
        }
      } catch (error) {
        this.$store.commit("setUserInfo", {});
        return false;
      }
    },
  },
  created() {
    if (JSON.stringify(this.location) == "{}") {
      this.getLocalion();
    }
    if (this.token) {
      this.getinfo()
    }
  },
  computed: {
    ...mapState({
      company_id: (state) => state.userInfo.company_id || 0,
      city: (state) => state.location.city || "青岛市",
      userAvr: (state) => state.userInfo.pic,
      nickname: (state) => state.userInfo.nickname,
      location: (state) => state.location,
    }),
    token: {
      cache: false,
      get: () => {
        return localStorage.getItem("token");
      },
    },
    member_id() {
      return localStorage.getItem("member_id") || null;
    },
  },
  watch: {
    $route(to,from){
      if (from.path === '/searchBar' && to.path !== "/searchBar") {
        this.searchText = ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.headers {
  .nav {
    background: #f5f5f6;
    .navbar {
      height: 46px;
      display: flex;
      align-items: center;
      line-height: 26px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
    }
    .city {
      color: #333333;
      img {
        vertical-align: sub;
        margin-right: 12px;
      }
      .cut {
        margin-left: 12px;
        color: #3d7eff;
        cursor: pointer;
      }
    }
    .funBtn {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #666;
      li {
        border-left: 1px solid #e6e6e6;
        padding: 0 12px;
        cursor: pointer;
        img {
          vertical-align: middle;
          margin-right: 7px;
        }
        /deep/.el-avatar img {
          width: 100%;
        }
      }
    }
  }
  .bannerShow {
    height: 112px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .logos {
      font-size: 30px;
      color: #101b28;
      line-height: 44px;
      > div {
        display: inline-block;
        margin-right: 32px;
        text-align: center;
        img {
          width: 88px;
          height: 40px;
          display: block;
        }
        span {
          font-size: 16px;
        }
      }
      span {
        // font-family: HYLingXinJ !important;
        font-weight: 800;
        vertical-align: super;
      }
    }
    .searchBar {
      margin-left: auto;
      display: flex;
      .searchInput {
        width: 464px;
        height: 40px;
        border: 2px solid #3d7eff;
        margin-right: 25px;
      }
      /deep/.el-input-group__prepend {
        width: 63px;
        background-color: #fff;
        border: none;
        color: #3d7eff;
        .el-select .el-input .el-select__caret {
          color: #3d7eff;
        }
      }

      /deep/.el-input__inner {
        border: none;
      }
      .searchBtn {
        width: 95px;
        height: 45px;
        background: #3d7eff;
        border-radius: 4px;
      }
    }
  }
  .navigation {
    color: #fff;
    background: #101b28;
    .navigat {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-around;
      div {
        width: 134px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        a {
          color: #fff;
        }
      }
      div.act {
        background: #3d7eff;
      }
    }
  }
}
.bannerImg {
  height: 258px;
  width: 100%;
  object-fit: cover;
}
</style>
