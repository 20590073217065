<template>
  <div class="footers">
    <div class="content fingerpost">
      <ul class="link">
        <li @click="topage('/pc_agree')">用户协议</li>
        <!-- <li>法律声明</li> -->
        <li @click="topage('/pc_policy')">隐私政策</li>
        <li @click="topage('/helpCenter', '277')">帮助中心</li>
        <li @click="topage('/franchisee')">加盟合作</li>
      </ul>
      <h3>交易指南</h3>
      <ul class="guide">
        <li>
          <img src="../../assets/newImg/dingdan.png" alt="" />
          <div>买家下单</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/fukuan.png" alt="" />
          <div>付款至平台</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/jiedan.png" alt="" />
          <div>搭建商接单</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/xiaoguotu.png" alt="" />
          <div>确认效果图</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/dajian.png" alt="" />
          <div>进场搭建</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/pingjia.png" alt="" />
          <div>评价</div>
        </li>
        <li class="arrows"><img src="../../assets/newImg/jiantou.png" alt="" /></li>
        <li>
          <img src="../../assets/newImg/wancheng.png" alt="" />
          <div>订单完成</div>
        </li>
      </ul>
      <h3>友商链接：</h3>
      <ul class="business">
        <li v-for="(item, index) in urlArr" :key="index" @click="toRouter(item.url)">{{item.title}}</li>
      </ul>
      <div class="code">
        <el-image
          style="width: 119px; height: 119px"
          :src="require('../../assets/newImg/wx.png')"
          fit="cover"
        ></el-image>
        <div>共享展具小程序</div>
      </div>
    </div>
    <div class="corporation">
      Copyright© 青岛七星国际会展科技有限公司 版权所有 备案号：<a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备15022445号-4</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlArr: [{
        title: '青岛元罡科技',
        url: 'https://yuangtech.com/'
      }, {
        title: '青岛佳朋展览展示有限公司',
        url: 'http://qdjiapeng.com/'
      }]
    }
  },
  methods: {
    topage(page, id, item) {
      let routeData = this.$router.resolve({ path: page, query: { id: id, item: item } });
      window.open(routeData.href, "_blank");
    },
    toRouter(url){
      window.open(url, '_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
.footers {
  background: #252f35;
  font-size: 14px;
  color: #fff;
  .fingerpost {
    position: relative;
    // height: 263px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin: 30px 0 38px;
      font-size: 16px;
      width: 826px;
      font-weight: 500;
    }
    .guide {
      display: flex;
      color: #e9f7fe;
      line-height: 32px;
      font-weight: 500;
      text-align: center;
      .arrows img {
        vertical-align: sub;
        margin: 0 23px;
      }
    }
    .business{
      display: grid;
      grid-template-columns: repeat(3, 33%);
      width: 826px;
      li{
        margin-bottom: 32px;
        cursor: pointer;
      }
    }
    .link {
      margin-top: 19px;
      font-weight: 700;
      display: flex;
      li {
        padding: 0 11px;
        cursor: pointer;
      }
      li + li {
        border-left: 1px solid #fff;
      }
    }
    .code {
      text-align: center;
      position: absolute;
      line-height: 39px;
      margin-top: 52px;
      right: 0;
    }
  }
  .corporation {
    height: 41px;
    background: #121d25;
    text-align: center;
    line-height: 41px;
    opacity: 0.8;
    a{
      color: #fff;
    }
  }
}
</style>
