import axios from "axios";
// import { Message } from "element-ui";
import {
  message
} from "../../utils/resetMessage";
import router from "../../router";
// import NProgress from "nprogress";
// import "nprogress/nprogress.css";
import cryptoJs from "crypto-js";

const service = axios.create({
  baseurl: process.env.BASE_API,
  timeout: 120000,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.data.token = token;
    }
    config.data.project_id = "5SBS96PA3TSOYOUPXG";
    if (
      config.url !== "/apis/uploadFile" &&
      config.url !== "/apis/uploadImg" &&
      config.url !== "/apis/memberimport" &&
      config.url !== "/apis/wel_import"
    ) {
      let encrypt = cryptoJs.DES.encrypt(
        JSON.stringify(config.data),
        cryptoJs.enc.Utf8.parse("dfsf3s@!Q$fse8"), {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      ).toString();
      config.data = {
        data: encrypt,
      };
    }
    // NProgress.start();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    // NProgress.done();
    let decrypt = cryptoJs.DES.decrypt(
      res.data,
      cryptoJs.enc.Utf8.parse("dfsf3s@!Q$fse8"), {
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7,
      }
    ).toString(cryptoJs.enc.Utf8);
    if (res.config.url !== "/apis/ali_ceshi") {
      res.data = JSON.parse(decrypt);
    }
    if (res.data.code === 200 && res.status === 200) {
      return res;
    } else {
      if (res.config.url !== "/apis/login") {
        errorHandle(res.data.code, res.data.message);
      } else {
        return res;
      }
    }
  },
  (error) => {
    const {
      response
    } = error;
    // NProgress.done();
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.data.code, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit('changeNetwork', false)
    }
  }
);

const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 503004:
      message.error('该商品已下架')
      break;
      // case 200001:
      //   Message.error('请填写完整')
      //   break
      // case 200002:
      //   Message.error('此账号或手机号已存在')
      //   break
      // case 200003:
      //   Message.error('组织代码或单位名称已存在')
      //   break
      // case 200004:
      //   Message.error('账号或密码错误')
      //   break
      // case 200005:
      //   Message.error('该账号已停用')
      //   break
      // case 200006:
      //   Message.error('密码错误')
      //   break
      // case 200007:
      //   // Message('两次密码不一致')
      //   Message.error('两次输入不一致')
      //   break
      // case 200008:
      //   Message.error('超管号不可被删除')
      //   break
      // case 200009:
      //   Message.error('验证码已超时')
      //   break
      // case 200010:
      //   Message.error('验证码错误')
      //   break
      // case 200011:
      //   Message.error('该账号已注销')
      //   break
      // case 200012:
      //   Message.error('该组织有正在使用的下级不可删除')
      //   break
      // case 200013:
      //   Message.error('该账号不存在')
      //   break
      // case 200014:
      //   Message.error('该组织数据异常，请联系运维人员')
      //   break
      // case 200015:
      //   Message.error('该项目运行中不可删除')
      //   break
      // case 200016:
      //   Message.error('该房间不存在')
      //   break
      // case 200017:
      //   Message.error('本次会议你无权进入')
      //   break
      // case 200018:
      //   Message.error('该角色正在被使用不可删除')
      //   break
      // case 200019:
      //   Message.error('产品类目不能全部删除')
      //   break
      // case 300001:
      //   Message.error('保存失败请重试')
      //   break
      // case 300002:
      //   Message.error('身份证号校验错误')
      //   break
      // case 40001:
      //   Message.error('该用户已存在')
      //   break
      // case 40002:
      //   Message.error('该用户未找到')
      //   break
      // case 40003:
      //   Message.error('请输入正确的身份证号')
      //   break
      // case 40004:
      //   Message.error('未提交审核信息')
      //   break
      // case 40005:
      //   Message.error('人员已存在')
      //   break
      // case 40006:
      //   Message.error('无法删除开始报名的活动')
      //   break
      // case 41001:
      //   Message.error('图片大小超过上限')
      //   break
      // case 41002:
      //   Message.error('请上传正确的图片')
      //   break
      // case 503001:
      //   Message.error('文件格式错误')
      //   break
      // case 503002:
      //   Message.error('该数据已存在')
      //   break
      // case 503003:
      //   Message.error('时间范围有冲突')
      //   break
      // case 503004:
      //   // Message.error('两次密码不一致')
      //   Message.error('菜单范围请选择1-14天')
      //   break
      // case 503005:
      //   Message.error('正在生效的菜单不能删除')
      //   break
      // case 503006:
      //   Message.error('该订单已完成或已退款无需审核')
      //   break
      // case 503007:
      //   Message.error('该订单非用户下单不可退')
      //   break
      // case 600001:
      //   Message.error('极光错误')
      //   break
      // case 900000:
      //   Message.error('token不能为空')
      //   break
      // 401: 未登录状态，跳转登录页
    case 900002:
      toLogin();
      break;
      // 403 token过期
      // 清除token并跳转登录页
    case 900001:
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("member_id");
      message.error("登录过期，请重新登录");
      // toLogin();
      // store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    case 900009:
      // Message.error('没有下一级啦！')
      break;
    case 503009:
      // Message.error('没有下一级啦！')
      break;
    case 600001:
      message.error("此群没有该成员！");
      break;
      // 404请求不存在
      // case 900003:
      //   Message.error('必传参数缺少或错误')
      //   break
      // case 900004:
      //   Message.error('你没有权限')
      //   break
      // case 900005:
      //   Message.error('本平台正在维护中')
      //   break
      // case 900006:
      //   Message.error('网络错误，请刷新重试')
      //   break
      // case 900007:
      //   Message.error('本餐厅本时段不供餐')
      //   break
      // case 900008:
      //   Message.error('该取餐号已存在')
      //   break
      // case 900009:
      //   Message.error('该取餐号已存在订单不可变更')
      //   break
      // case 900010:
      //   Message.error('该用户号不存在')
      //   break
      // case 900011:
      //   Message.error('该用户已被绑定')
      //   break
    default:
      message.error(other);
  }
};

export default service;